@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


.App {
  text-align: center;
}


.App-link {
  color: #61dafb;
}

.btn {
  background: linear-gradient(97.78deg, var(--ds-background-accent-purple-bolder, #65B1DB) 17.5%, var(--ds-background-accent-magenta-subtle, #A75EC0) 113.39%);
  color: white;
  font-family: Poppins, sans-serif;
  font-weight : bold ;
  border: none;
  border-radius: 15px;
  padding: 6px;
  width: 150px;
}
