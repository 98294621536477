.main-nav {

  width: 100%;
  background: rgb(156, 180, 233);
  padding: 30px 0;
  text-align: left;
  display: flex;
}

.main-nav img {
  margin-left: 5%;
  filter: invert(25%);
  width: 36px;
}

.main-nav__items {
  margin: auto;
  list-style: none; /* Remove the bullet black dot*/
}

.main-nav__item {
  display: inline-block;
  font-weight: bold;
  text-align: center;
}

.main-nav__item a {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
}

.main-nav__item a:hover,
.main-nav__item a:active {
  color:white;
  border-bottom: 5px solid white;
}

.main-nav__btn {
  margin-right: 20px;
}
